<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    class="cardDialog"
    width="500px"
    center
  >
    <div :class="[show_more ? 'show_true' : 'show_false']" class="myBox" v-loading="loading">
      <userMsg
        :item="item"
        :isDetail="isDetail"
        @excardSuccess="excardSuccess"
      ></userMsg>
      <div v-if="show_more" class="show_box">
        <!-- 擅长业务 -->
        <div>
          <h1>{{ $t("Good_at_business_exp") }}</h1>
          <div class="green_box">
            <span
              v-for="(ite, i) in item.business_classification_info.Convertional"
              :key="i"
            >
              #{{ ite.desc_en | priorFormat(ite.desc_zh, LOCALE) }}
            </span>

            <span
              v-for="(it, key) in item.business_classification_info[
                'Particular Cargo'
              ]"
              :key="key"
            >
              #{{ it.desc_en | priorFormat(it.desc_zh, LOCALE) }}
            </span>

            <span
              v-for="itm in item.business_classification_info[
                'Dangerous Cargo'
              ]"
              :key="itm.id"
            >
              #{{ itm.desc_en | priorFormat(itm.desc_zh, LOCALE) }}
            </span>
            <template v-if="item.business_classification_info['user_tags']">
              <span
              v-for="itm in item.business_classification_info[
                'user_tags'
              ]"
              :key="itm.id"
            >
              #{{ itm.desc_en | priorFormat(itm.desc_zh, LOCALE) }}
            </span>
            </template>
          </div>
        </div>
        <!-- 航线 -->
        <div>
          <h1>{{ $t("BussinessMajor") }}</h1>
          <div class="green_box" v-if="item.shipping_line_info.length != 0">
            <span v-for="(item, i) in item.shipping_line_info" :key="i">{{
              item.name_en | priorFormat(item.name_cn, LOCALE)
            }}</span>
          </div>
          <div v-else>-</div>
        </div>
        <!-- 个人介绍 -->
        <div class="Personal_introduction">
          <h1>{{ $t("Personal_introduction") }}</h1>
          <p>{{ item.user_profile | textFormat }}</p>
        </div>
        <!-- 联系方式 -->
        <div class="lxfs">
          <div class="item">
            <label>{{ $t("company_net") }}</label>
            <span v-if="item.foreign_info && item.foreign_info.website">{{
              item.foreign_info.website | textFormat
            }}</span>
            <span v-else>{{ item.website | textFormat }}</span>
          </div>
          <div v-if="item.is_follow == 1 || item.user_id == USER_INFO.id">
            <div class="item">
              <label>{{ $t("wechat") }}</label
              ><span>{{ item.wechat | textFormat }}</span
              ><i
                @click="copyText('复制文本')"
                class="el-icon-document-copy"
              ></i>
            </div>
            <div class="item">
              <label>Facebook</label
              ><span>{{ item.facebook | textFormat }}</span
              ><i
                @click="copyText('复制文本')"
                class="el-icon-document-copy"
              ></i>
            </div>
            <div class="item">
              <label>{{ $t("linkedIn") }}</label
              ><span>{{ item.linkedin | textFormat }}</span
              ><i
                @click="copyText('复制文本')"
                class="el-icon-document-copy"
              ></i>
            </div>
            <div class="item">
              <label>Skype</label><span>{{ item.skype | textFormat }}</span
              ><i
                @click="copyText('复制文本')"
                class="el-icon-document-copy"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业视频 -->
      <div class="videoList" v-if="show_more">
        <div class="item" v-for="(item, index) in item.video_list" :key="index">
          <div class="img pointer img-wrap">
            <el-image :src="item.cover_url" fit="cover" class="item"></el-image>
            <div class="mark">
              <i
                class="el-icon-video-play cursor"
                @click="handleContent(item)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <div class="box" v-if="USER_INFO.id" @click="packupChange">
        <div
          v-if="
            (isDetail && !show_more) ||
            (isDetail && item.is_follow == 1) ||
            (isDetail && item.user_id == USER_INFO.id) ||
            !isDetail
          "
        >
          <span>{{ show_more ? $t("packup") : $t("Expand_more") }}</span>
          <i
            :class="[
              show_more ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right',
            ]"
            class="i_rotate"
          ></i>
        </div>
        <div class="btn_footer_box" v-else-if="isDetail && show_more">
          <el-button
            :disabled="item.user_id == USER_INFO.id"
            @click="ExchangeCard(2)"
            size="mini"
            class="btn_footer"
            >{{ $t("noChange") }}</el-button
          >
          <el-button
            :disabled="item.user_id == USER_INFO.id"
            @click="ExchangeCard(1)"
            type="primary"
            size="mini"
            class="btn_footer"
            >{{ $t("change") }}</el-button
          >
        </div>
      </div>

      <div class="box" v-else @click="$router.push('/sign')">
        <span>{{ show_more ? $t("packup") : $t("Expand_more") }}</span>
        <i
          :class="[
            show_more ? 'el-icon-d-arrow-left' : 'el-icon-d-arrow-right',
          ]"
          class="i_rotate"
        ></i>
      </div>
    </div>
    <videoPlay ref="vidoePlay"></videoPlay>
  </el-dialog>
</template>
<script>
import userMsg from "@/baseComponents/card/userMsgIs";
import videoPlay from "@/baseComponents/videoPlay";
export default {
  props: {
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    userMsg,
    videoPlay,
  },
  data() {
    return {
      dialogFormVisible: false,
      show_more: false,
      row: {},
      item: {},
      loading: false
    };
  },
  mounted() {
    this.$GLOBALEVENT.$on("OPENISUSERCARD", async (row) => {
      this.row = row.row;
      this.show_more = false;
      this.dialogFormVisible = row.show;
      this.getList();
    });
  },
  destroyed() {
    this.$GLOBALEVENT.$off("OPENISUSERCARD");
  },
  methods: {
    async getList() {
      //获取卡片信息
      let params = {
        user_id: this.row.user_id,
        followed_user_id: this.USER_INFO.id,
      };
      this.loading = true
      let cardMsg = await this.$store.dispatch(
        "baseStore/getCollectionDetail",
        params
      );
      if(cardMsg.data.mobile){
        // cardMsg.data.hideMobile=cardMsg.data.mobile.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2")
        if(cardMsg.data.mobile.length>4){
          let start=cardMsg.data.mobile.slice(0,cardMsg.data.mobile.length-4)
          let end=cardMsg.data.mobile.slice(-4)
          if(start.length>4){
            //在截取
            let start1=start.slice(0,start.length-4)
            let start2=start.slice(-4)
            cardMsg.data.hideMobile=start1+Array(4).fill('*').join("")+end
          }else{
            cardMsg.data.hideMobile=Array(4).fill('*').join("")+end
          }
        }else{
          cardMsg.data.hideMobile=Array(4).fill('*').join("")
        }
      }
      if(cardMsg.data.email){
        if(String(cardMsg.data.email).indexOf('@')>0){
          let str=cardMsg.data.email.split('@'),_s=''
          if(str[0].length >= 4){
            let a=str[0].slice(0,2)
            let b=str[0].slice(-2)
            cardMsg.data.hideEmail=`${a}****${b}@${str[1]}`
          }else{
            cardMsg.data.hideEmail=Array(4).fill('*').join("")+'@'+str[1]
          }
        }else{
          cardMsg.data.hideEmail=Array(String(cardMsg.data.email).length).fill('*').join("")
        }
      }
      this.loading = false
      this.item = cardMsg.data;
      if (cardMsg.data.foreign_info != "") {
        this.item.foreign_info = JSON.parse(cardMsg.data.foreign_info);
      }
    },
    //  交换名片
    async ExchangeCard(type) {
      let params = {
        followed_user_id: this.row.user_id,
        user_id: this.USER_INFO.id,
        source: this.PJSource,
      };
      params.status = type;
      let exchangeCard = await this.$store.dispatch(
        "baseConsole/handleChangeCard",
        params
      );
      if (exchangeCard && exchangeCard.success) {
        if (this.isDetail && type == 1) {
          this.item.is_follow = 1;
          this.$osTip();
        } else if (type == 2) {
          this.dialogFormVisible = false;
          this.$osTip();
        }
        this.$emit("successChange");
      }
      if (exchangeCard && exchangeCard.message == "already exist") {
        this.$message.warning(this.$t("card_exit"));
      }
    },
    packupChange() {
      if (
        this.isDetail &&
        this.item.is_follow != 1 &&
        this.item.user_id != this.USER_INFO.id
      ) {
        this.show_more = true;
      } else {
        this.show_more = !this.show_more;
      }
    },
    handleContent(item) {
      this.$refs.vidoePlay.startPlay(item.aliyun_id);
    },
    copyText(text) {
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${text}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
    },
    excardSuccess() {
      this.getList();
      this.$emit("excardChange", this.item.is_follow);
      this.$emit("excardSucceed", this.item);
    },
  },
};
</script>
<style lang="less" scoped>
.show_true {
  height: 600px;
}
.show_false {
  height: 328px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.cardDialog {
  /deep/ .el-dialog__header {
    height: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  .myBox {
    padding: 0 30px;
    padding-top: 44px;
    overflow-y: scroll;
    overflow-x: hidden;
    .show_box {
      h1 {
        font-size: 14px;
        font-weight: bold;
        color: #1f292e;
        padding-top: 21px;
        padding-bottom: 12px;
      }
      .green_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        span {
          // height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #1ea89d;
          line-height: 17px;
          padding-left: 10px;
        }
      }
      .Personal_introduction {
        p {
          font-size: 12px;
          font-weight: 400;
          color: #1f292e;
          line-height: 17px;
        }
      }
      .lxfs {
        padding-top: 20px;
        .item {
          margin-bottom: 25px;
          display: flex;
          align-items: center;
          label {
            width: 78px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            margin-right: 24px;
          }
          span {
            font-size: 14px;
            font-weight: 800;
            color: #333333;
          }
          i {
            margin-left: 5px;
            font-size: 24px;
            cursor: pointer;
            color: #539cff;
          }
        }
      }
    }
  }
  .btn_footer_box {
    text-align: center;
    .btn_footer {
      width: 200px;
      // background-color: #1890FF;
      margin: -5px 5px 0 5px;
    }
  }

  .i_rotate {
    transform: rotate(90deg);
  }
  /deep/ .el-dialog__footer {
    border-top: 1px solid #d8d8d8;
    height: 55px;
    padding: 16px;
    cursor: pointer;
    span,
    i {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.videoList {
  display: flex;
  position: relative;
  width: 100%;
  overflow-x: scroll;
  .item {
    width: 131px;
    margin-right: 20px;
    height: 100%;
    .img {
      width: 131px;
      height: 91px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .img-wrap {
    position: relative;
    .mark {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 91px;
      line-height: 91px;
      text-align: center;
      .el-icon-video-play {
        font-size: 30px;
        color: #aaa;
      }
    }
    .mark:hover {
      background: rgba(0, 0, 0, 0.6);
    }
    .mark:hover .el-icon-video-play {
      color: #fff;
    }
  }
}
</style>
